// TODO: The data handling is a little obtuse here. For multiple carousel screens we grab the values by an integer index field on the page Context
// but when its a single screen, we get vals directly
// This kind of obsfucation can lead to confusion if not kept at bay
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useAppSelector, useAppDispatch } from '@hooks/redux-hooks';
import { actions as routeActions } from '@slices/routeSlice';
import { Link, PageProps } from 'gatsby';
import { Heading, Flex, Para, Button, Label } from 'workspace-core-ui';
import styled from 'styled-components';
import css from '@styled-system/css';
import Layout from '@containers/Layout';
import BodyWrapper from '@components/BodyWrapper';
import { setHeaderType } from '@slices/gameStateSlice';
import useTranslation from '@hooks/useTranslation';
import useSound from '@hooks/useSound';
import { motion, AnimatePresence } from 'framer-motion';
import CustomMdxRenderer from '@containers/CustomMdxRenderer';
import useCustomReducedMotion from '@hooks/useCustomReducedMotion';
import useNavigateLog from '@hooks/useNavigateLog';
import { wrap } from '@utils/wrap';
import BackgroundImage from '@components/BackgroundImage';
import getSymbol from '@utils/getSymbol';
import { WINDOW_HASH } from '@sharedConstants';
import { selectCurrentModule, selectProfile } from '../module/profile-slice';

const NextButton = styled(Button)<{ to: string }>(
  css({
    textDecoration: 'none',
  }),
);

type CarouselContext = {
  url: string;
  screenData: {
    Name: string;
    typeOfScreen: 'Carousel'; // this might look funny, but if its not then we will get an error (telling us something went wrong)
    Index: number;
    Header_Text: Content;
    Body_Text: Content;
    Symbol: {
      localFiles: {
        childSvg: {
          content: {
            data: string;
            height: number;
            width: number;
          };
        };
      };
    };
  }[];
}

export interface Content {
  Content_Type: string;
  Text: {
    childMdx: {
      body: string;
      rawBody: string;
    };
  };
}

const variants = reducedMotion => ({
  enter: (direction: number) => ({
    x: reducedMotion ? 0 : direction > 0 ? 1000 : -1000,
    opacity: 0,
  }),
  center: {
    zIndex: 1,
    x: 0,
    opacity: 1,
  },
  exit: (direction: number) => ({
    zIndex: 0,
    x: reducedMotion ? 0 : direction < 0 ? 1000 : -1000,
    opacity: 0,
  }),
});

const BottomContentWrapper = styled(Flex)(
  css({
    isolation: 'isolate',
    flex: 1,
    flexDirection: 'column',
    alignSelf: 'center',
    alignItems: 'center',
    mt: 7,
  }),
);

const Pip = styled(Flex)<{ isSelected: boolean }>(({ isSelected }) =>
  css({
    mx: 3,
    borderRadius: '50%',
    height: '10px',
    width: '10px',
    border: '2px solid',
    borderColor: 'foreground',
    backgroundColor: isSelected ? 'foreground' : 'transparent',
  }),
);

const CarouselPage = (
  props: PageProps<Queries.CarouselPageQuery, CarouselContext>,
) => {
  const [startTimestamp, setStartTimestamp] = useState<number>();
  const carouselPageBgSymbol = getSymbol(props.data.carouselPageBgSymbol);
  useNavigateLog({ questionName: 'carousel' });
  const [[page, direction], setPage] = useState([0, 0]); // this denotes what 'mini-screen' you are on. The screens on this screen, ahem, are not really routes, just a slideshow of content that depends on what you input on airtable
  const currentModule = useAppSelector(selectCurrentModule);
  // paginate is happening absolutely (ie 1, 2, 3, 4, 5...), but this will loop that so that each screen is "new", with this you can also loop with only one item if need be.
  // we need to force cast the screen data to an array
  const { screenData } = props.pageContext;
  let slides = screenData;

  if (!Array.isArray(slides)) {
    // assume that is an object
    slides = [screenData];
  }

  const loopingPageIndex = wrap(0, slides.length, page);
  const { url } = props.pageContext;

  const { playSound } = useSound();
  const { nextRoute } = useAppSelector(state => state.route);
  const dispatch = useAppDispatch();
  const { t, g } = useTranslation();

  const renderedSlideBody = slides.map(e => g(e.Body_Text, true));
  const profile = useAppSelector(selectProfile);

  useLayoutEffect(() => {
    // record when user officially "sees" the carousel
    setStartTimestamp(Date.now());
  }, []);

  useEffect(() => {
    dispatch(setHeaderType({ headerType: 'minimal' }));
    dispatch(routeActions.inferNextRoute({ compareAgainst: url, profile, module: currentModule?.Name }));
  }, [dispatch, url, profile]);

  const isAtEndSlide = loopingPageIndex === slides.length - 1;

  const paginate = (newDirection: number) => {
    setPage([page + newDirection, newDirection]);
  };

  return (
    <Layout bg="primaryDark">
      {carouselPageBgSymbol && (
        <BackgroundImage
          imageData={carouselPageBgSymbol.data}
          imageType={carouselPageBgSymbol.type}
        />
      )}
      <AnimatePresence initial={false} custom={direction}>
        <BodyWrapper p={5}>
          <motion.div
            key={page}
            custom={direction}
            variants={variants(useCustomReducedMotion())}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: 'spring', stiffness: 300, damping: 30 },
              opacity: { duration: 0.2 },
            }}
          >
            <Flex flexDirection="column" alignItems="center">
              <Flex flex={0.5} />

              {slides?.[loopingPageIndex]?.Symbol?.localFiles?.childSvg?.content
                ?.data && (
                  <Flex
                    dangerouslySetInnerHTML={{
                      __html:
                        slides[loopingPageIndex].Symbol.localFiles.childSvg
                          .content.data,
                    }}
                    sx={{
                      svg: {
                        position: 'relative',
                        left: '50%',
                        transform: 'translate(-50%, 0%)',
                        height: ['90px', 'auto', 'auto'],
                        top: 0,
                        mb: 5,
                      },
                    }}
                  />
                )}
              <Flex
                flexDirection="column"
                sx={{
                  '.gatsby-resp-image-wrapper': {
                    width: 'clamp(30px, 40vh - 3rem, 100%)',
                    m: 3,
                  },
                }}
              >
                <Heading mb={4} variant="h2">
                  {g(slides[loopingPageIndex].Header_Text)}
                </Heading>
                <Para variant="p2" maxWidth="720px">
                  <CustomMdxRenderer>
                    {renderedSlideBody[loopingPageIndex]}
                  </CustomMdxRenderer>
                </Para>
              </Flex>
              <BottomContentWrapper>
                <Flex
                  sx={{
                    width: ['250px', '250px', '350px'],
                    justifyContent: 'space-evenly',
                  }}
                >
                  {!!page && (
                    <Button
                      onPress={() => {
                        playSound('Button');
                        paginate(-1);
                      }}
                    >
                      <Label variant="l1">{t('Carousel Back')}</Label>
                    </Button>
                  )}

                  {isAtEndSlide ? (
                    <NextButton
                      forwardedAs={Link}
                      data-cy="next"
                      to={nextRoute?.url + WINDOW_HASH || `/${WINDOW_HASH}`}
                      replace
                    >
                      <Label variant="l1">{t('Carousel End')}</Label>
                    </NextButton>
                  ) : (
                    <NextButton
                      data-cy="next"
                      onPress={() => {
                        playSound('Button');
                        paginate(1);
                      }}
                    >
                      <Label variant="l1">{t('Carousel Next')}</Label>
                    </NextButton>
                  )}
                </Flex>
                {slides.length > 1 && (
                  <Flex mt={6} flexDirection="row">
                    {slides.map((slide, index) => (
                      <Pip
                        as="li"
                        isSelected={index === loopingPageIndex}
                        key={slide.Name}
                      />
                    ))}
                  </Flex>
                )}
              </BottomContentWrapper>
            </Flex>
          </motion.div>
        </BodyWrapper>
      </AnimatePresence>
    </Layout>
  );
};


export default CarouselPage;
