import { Flex } from 'workspace-core-ui';
import styled from 'styled-components';
import css from '@styled-system/css';

const BodyWrapper = styled(Flex)(
  css({
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    minHeight: '100%',
    isolation: 'isolate',
  }),
);

export default BodyWrapper;
