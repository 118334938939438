import React from 'react';
import { graphql } from 'gatsby';
import Seo from '@containers/Seo';
import checkFeatureFlag from '@utils/checkFeatureFlag';
import CarouselV1 from './Carousel-v1';
import CarouselV2 from './Carousel-v2';

const Carousel = checkFeatureFlag('MODULES') === 'FLAG_VALID' ? CarouselV2 : CarouselV1;

export const query = graphql`
  query CarouselPage {
    carouselPageBgSymbol: allAirtable(
      filter: {
        table: { eq: "Game Elements" }
        data: { Name: { eq: "Claim Background Image" } }
      }
    ) {
      ...SvgGetFragment
      ...GatsbyImageGetFragmentNoPlaceholder
    }
  }
`;

export const Head = () => <Seo />;

export default Carousel;