import useCustomReducedMotion from '@hooks/useCustomReducedMotion';
import { motion } from 'framer-motion';
import React, { ReactNode } from 'react';
import { Box } from 'workspace-core-ui';
import { createGlobalStyle } from 'styled-components';

// this style is so that opacity transitions are less jarring
const GlobalStyle = createGlobalStyle`
  body, html {
    background-color: ${({ theme, bgOverride }) =>
      bgOverride || theme.colors.background};
  }`;

const Layout = ({
  children,
  shouldAnimate = true,
  bgOverride = null,
  location,
  ...delegated
}: {
  children: ReactNode;
  shouldAnimate?: boolean;
  bgOverride?: string | null;
  location?: object;
  // TODO: fix this
  [x: string]: any;
}) => {
  shouldAnimate = !useCustomReducedMotion() && shouldAnimate;
  return (
    <>
      <GlobalStyle bgOverride={bgOverride} />
      {shouldAnimate ? (
        <Box
          as={motion.main}
          initial={{ opacity: 0 }}
          style={{
            height: '100%',
            opacity: 0,
          }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{
            type: 'spring',
            mass: 0.35,
            stiffness: 75,
            duration: 0.3,
          }}
          {...delegated}
        >
          {children}
        </Box>
      ) : (
        <Box height="100%" {...delegated}>
          {children}
        </Box>
      )}
    </>
  );
};

export default Layout;
